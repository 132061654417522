<template>
    <body class="as-app-body as-app">
        <Header @play-tuto="startIntro(true)"/>
        <as-responsive-content ref="responsive">
            <aside class="as-sidebar as-sidebar--l as-sidebar--left" data-name="Metrics">
                <section class="as-container as-container--border">
                    <span class="info" @click="explanations()"> {{ $t('help_t') }} </span>
                    <div class="as-box segment">
                        <span> {{ $t('segment') }} </span>
                        <select :disabled="loading" v-model="segment">
                            <option v-for="seg in segments" :key="seg" :value="seg"> {{ $t(seg) }} </option>
                        </select>
                    </div>
                    <div class="as-box key-indicators">
                        <h1 class="as-title"> {{$t('key-indicators')}} </h1>
                        <div class="stats" v-if="isInit">
                            <span>
                                <component :is="'icon-users'"/>
                                <span v-html="$t(popIndicLoading ? 'loading-html' : 'raster_stats', [formatNumber(rasterStats[totalKey])]) ">   </span>
                            </span>
                            <Badges :values="rasterStats" :keys="badgeLabels" :targetType="'population'" :individualType="'persons'"/>
                        </div>
                        <div class="stats" v-if="isInit">
                            <span>
                                <component :is="'icon-target'"/> 
                                <span v-html="$t('pois_stats', [formatNumber(poisStats.matching), formatNumber(poisStats[totalKey])]) "> </span>
                            </span>
                            <Badges :values="poisStats" :keys="badgeLabels" :targetType="$t(segment)" :individualType="'places'"/>
                        </div>
                    </div>
                    <div class="as-box filter-slider">
                        <div class="pois-title"> 
                            <h1> {{ $t('pois') }} </h1>
                            <label> <input type="checkbox" v-model="showPois"> Show </label>
                        </div>
                        <h2> {{ $t('filter-display') }} </h2>
                        <div class="step-slider">
                            <vue-slider v-model="filterValue" :v-data="filterLabels" 
                                :marks="true" :tooltip="'none'" :lazy="true"
                                :adsorb="true" @change="filterTargets">
                            </vue-slider> 
                        </div>
                        <span> <strong> {{ displayedPois }} </strong> {{$t('displayed-map')}} </span> <br>
                        <div :class="['advanced-filters', {opened: showAdvancedFilters}]">
                            <h2  @click="showAdvancedFilters = !showAdvancedFilters"> {{ $t('advanced_filters') }}  
                                <span id="help-advanced" :content="$t('advanced_explain')" v-tippy> ? </span>
                            </h2>
                            <div class="contained">
                                <label> <input type="checkbox" v-model="advancedActivated" @change="filterTargets"> {{ $t('activate_advanced') }}</label>
                                <br>
                                <div v-if="filterableByMobileSpeed">
                                    <span> {{ $t('filter_mobile_speed')}} : {{ formatNumber(mobileSpeedFilter[0]) }} - {{ formatNumber(mobileSpeedFilter[1]) }} Mbit/s </span>
                                    <vue-slider :height="6" :width="'80%'" v-model="mobileSpeedFilterPct" :min="0" :max="100" :enable-cross="false"  
                                        @change="filterTargets" :lazy="true" tooltip="hover" :tooltip-formatter="(val) => logRangeOrValue(val, maxSpeedMobile, true)"> </vue-slider>
                                </div>
                                <div v-if="filterableByFixedSpeed">
                                    <span> {{ $t('filter_fixed_speed')}} : {{ formatNumber(fixedSpeedFilter[0]) }} - {{ formatNumber(fixedSpeedFilter[1]) }} Mbit/s </span>
                                    <vue-slider :height="6" :width="'80%'" v-model="fixedSpeedFilterPct" :min="0" :max="100" :enable-cross="false" 
                                        @change="filterTargets" :lazy="true" tooltip="hover" :tooltip-formatter="(val) => logRangeOrValue(val, maxSpeedFixed, true)"> </vue-slider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="export">
                        <div :class="['btn', loading ? 'disabled' : '']" @click="exportQuery"> <component :is="'icon-download'"/> {{$t('export')}} </div>
                    </div>
                </section>
                <section class="as-container as-container--scrollable histograms">
                    <div class="as-box--full">
                        <div class="histo">
                            <h2> {{$t('top-plans')}} </h2>
                            <div id="widget-plans" class="ct-chart" v-if="Object.keys(customerPlans).length > 0"> </div>
                            <h3 v-else> {{ $t('no-point-area') }} </h3>
                        </div>
                        <div class="histo">
                            <h2> {{$t('top-categories')}} </h2>
                            <div id="widget-categories" class="ct-chart" v-if="Object.keys(displayedCategories).length > 0"> </div>
                            <h3 v-else> {{ $t('no-point-area') }} </h3>
                        </div>
                    </div>
                </section>
            </aside>

            <main class="as-main">
                <div class="as-map-area">
                    <div id="map">
                        <div id="top-right" ref="map_ui">
                        <LayerSelector v-if="isInit"  :sections="groupedOverlays" :map="map" @layer-change="updateLegend"> 
                            <div>
                                <LayerControler v-model:layerSelected="admColorSelected" :layers="admSelections" title="adm-coloring"> </LayerControler>
                                <LayerControler v-model:layerSelected="covSelected" v-model:layerOpacity="covOpacity" :layers="coverages" title="coverage-layers"> </LayerControler>
                            </div>
                        </LayerSelector>
                        <Legend ref="legend" :title="legend.title" :data="legend.data" v-if="legend"> </Legend>
                        <Legend v-if="legendWs" ref="legend_ws" :title="legendWs.title" :data="legendWs.data" > </Legend>
                        <Legend v-if="legendRwi" ref="legend_rwi" :title="legendRwi.title" :data="legendRwi.data" > </Legend>
                        </div>
                    </div>
                </div>
            </main>
        </as-responsive-content>
        <TabbedInfo ref="infos" :title="infoTitle" :values="infoValues" />
         <vue-final-modal v-model="showModal" name="explanations" classes="modal-container" content-class="modal-content">
            <div v-html="$t(`help_${countryCode}`)"></div>
            <a :href="`/api/export/export_all_pois?ccode=${countryCode}`" class="btn"> {{ $t('export_pois') }} </a>
        </vue-final-modal>
    </body>
</template>


<script>
import Badges from '@/components/Badges.vue';
import TabbedInfo from '@/components/TabbedInfo.vue';
import LayerSelector from '@/components/LayerSelector.vue';
import LayerControler from '@/components/LayerControler.vue';
import Header from '@/components/Header.vue';
import VueSlider from 'vue-slider-component'
import Legend from '@/components/Legend.vue';
import CustomMarker from '@/util/CustomMarker.js'

import Chartist from 'chartist';
import L from 'leaflet';
import 'leaflet-hash';
import 'leaflet.fullscreen';
import 'leaflet-basemaps';
import 'leaflet-spin';
import { nextTick } from 'vue';
import {interpolateBlues} from 'd3-scale-chromatic';
import { PruneCluster, PruneClusterForLeaflet } from '../util/PruneCluster';
import {baseLayers, labelLayers} from '@/util/baseLayers.js';
import {Tutorial, introSteps} from '@/util/tutorial.js';
import {disablePropagation} from '@/util/controlWrapper.js';
import defaultDict from '@/util/defaultDict.js';
import {styles, getStyle, colorWs} from '@/style/layerStyles.js';
import {getSteps, stepsToLegend, circleLegend, rwiLegend} from '@/util/legend.js';
import * as d3 from 'd3-format';
import { downloadFile } from '@/util/util.js';
import { totalKey } from '@/util/common.js';


delete L.Icon.Default.prototype._getIconUrl;

// make webpack import the marker
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const emptyGeoJson = L.geoJSON({
    "type": "FeatureCollection",
    "features": []
});

const targetMarker = new CustomMarker({
    iconPath: require('@/assets/img/icons/target.svg'),
    whiteIcon: true,
    color: "#e48c07",
});

const userMarker = new CustomMarker({
    iconPath: require('@/assets/img/icons/user.svg'),
    color: "#0a7c54",
    whiteIcon: true,
});

const dealerMarker = new CustomMarker({
    iconPath: require('@/assets/img/icons/shop.svg'),
    color: "#808080",
    whiteIcon: true,
});

const tilesPrefix = '/tiles/singleband/';
const hrslSuffix = `?colormap=ylorrd&stretch_range=[2, 50]`;

// 1 = poor, 2 = good
const colorMapping = JSON.stringify({1: [30, 126, 92], 2: [5, 61, 41], 3:[89, 204, 164]});
const coverageSuffix = `?colormap=explicit&explicit_color_map=${colorMapping}`;
const chartOptions = {
    horizontalBars: true,
    seriesBarDistance: 14,
    axisY: {
        showGrid: false,
    },
    distributeSeries: true
};
const tileLayerOptions = {
    maxNativeZoom: 13,
    maxZoom: 19,
};
const maxCircleRadius = 80;  // in pixels

export default {
    name: 'map-view',
    props: {
        'countryCode': String,
    },
    components: {
        LayerSelector, LayerControler, Header, TabbedInfo, VueSlider, Badges, Legend,
    },
    data() {
        return {
            infoTitle: null,
            infoValues: {},
            vw: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
            covSelected: 0,
            covOpacity: 20,
            isInit: false,
            popIndicLoading: false,
            filterValue: this.$t('poor4g'),
            displayedPois: 0,
            segments: [],
            segment: null,
            rasterStats: {},
            poisStats: {},
            customerPlans: {},
            displayedCategories: {},
            admColorSelected: 0,
            legend: null,
            legendWs: null,
            legendRwi: null,
            maxSpeedFixed: 10,
            maxSpeedMobile: 10,
            mobileSpeedFilterPct: [25, 100],
            fixedSpeedFilterPct: [42, 100],
            loading: false,
            showAdvancedFilters: false,
            advancedActivated: false,
            showModal: false,
            badgeLabels: [],
            admSelections: [],
            showPois: true,
            speedFilterConfig: [],
        }
    },
    
    beforeCreate() {
        this.totalKey = totalKey;
        this.formatNumber = d3.format(',.0f');
        this.availableLegends = [];
        this.filterStr = [ "outside3g", "poor3g", "outside4g", "poor4g", "filter_all"]
        this.segmentPoisLayers = {};
        this.iconMapping = {'raster_stats': 'icon-users', 'pois_stats': 'icon-target'};
        this.translate = (...args) => this.$t(...args);
    },

    mounted() {
        this.$refs.responsive.addEventListener('ready', () => {
            import(`../../../country_config/${this.countryCode}.json`).then( config => {
                this.config = config.default;
                this.admConfig = this.config.adm;
                this.segments = this.config.segments;
                this.admSelections = this.admConfig.popViews;
                this.tilesConfig = this.config.tiles;
                this.speedFilterConfig = this.config.filterSpeeds;
                if (this.speedFilterConfig.mobile !== undefined) this.mobileSpeedFilterPct = this.speedFilterConfig.mobile;
                if (this.speedFilterConfig.fixed !== undefined) this.fixedSpeedFilterPct = this.speedFilterConfig.fixed;
                this.coverages = this.tilesConfig.coverages.map(cov => cov.name);
                this.defineMasks(this.config.masks)
                this.initMap();
            });
        });
        this.$refs.responsive.addEventListener('sectionChanged', () => { this.drawHistograms() });
        // get config
        this.tourTuto = new Tutorial('intro', introSteps, null, null);
        this.startIntro(false, this.translate);
    },

    methods: {
        initMap() {
            this.map = L.map('map', {
                zoomControl:true,
                maxZoom:19,
                minZoom:5,
                fullscreenControl: true,
                fullscreenControlOptions: {
                    forceSeparateButton: true,
                },
                center: this.config.center,
                zoom: this.config.zoom,
            });
            this.defaultCursor = this.map._container.style.cursor;
            new L.Hash(this.map);
            this.$plausible('Map accessed', {props: {user: localStorage.getItem('username')}});
            this.initLayers();
            this.getKeyIndicators();
            this.map.on('click touch', e => this.onMapClick(e));
            this.map.on("moveend", () => {
                if (this.timeoutId) clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(() => {
                    this.getKeyIndicators();
                    this.countTargets();
                }, 500);
            });
        },

        async initLayers() {
            const labels = this.map.createPane('labels');
            const coverage = this.map.createPane('coverage');
            coverage.style.zIndex = 401;
            labels.style.zIndex = 555;
            const ccode = this.countryCode.toLowerCase();
            this.coverageLayers = this.tilesConfig.coverages.map(covConfig => {
                const url = `${tilesPrefix}${ccode}/${covConfig.date}/${covConfig.name}/{z}/{x}/{y}.png${coverageSuffix}`;
                return L.tileLayer(url, {...tileLayerOptions, opacity: this.covOpacity/100, pane: 'coverage'});
            });
            const rasterPopDef = this.tilesConfig.population;
            this.popTile = L.tileLayer(`${tilesPrefix}${ccode}/${rasterPopDef.date}/${rasterPopDef.name}/{z}/{x}/{y}.png${hrslSuffix}`, {
                attribution: 'Worldpop',
                opacity : "0.65"
            });

            const baseMaps = L.control.basemaps({ 
                basemaps: baseLayers,
                position: 'bottomleft',
            });
            this.map.addControl(baseMaps);
            this.map.removeLayer(baseLayers[0]);
            this.map.addLayer(baseLayers[0]);
            this.map.addLayer(labelLayers[this.$i18n.locale]);
            this.map.addLayer(this.popTile);
            this.map.addLayer(this.coverageLayers[0]);

            await this.getCustomers();
            await this.getAdm();
            
            this.groupedOverlays = {
                "rasters" : {
                    exclusive: false,
                    layers: {
                        "pop": this.popTile,
                        "customers": this.customersCluster,
                        "wards": this.admLayer,
                    }
                },
            };
            if (this.config.beamFile) {
                const beams = await import(`@/assets/layers/${this.config.beamFile}`);
                this.beamsLayer = L.geoJSON(beams, {style: styles['beams']});
                this.groupedOverlays.rasters.layers['beams'] = this.beamsLayer;
            }
            if (this.config.dealersFile) {
                const dealers = await import(`@/assets/layers/${this.config.dealersFile}`);
                this.dealersLayer = L.geoJSON(dealers, {
                    pointToLayer: (feat, latlng) => { 
                        return L.marker(latlng, {icon: dealerMarker}).bindPopup(() => {
                            this.setInfos(feat.properties);
                            return this.infos;
                        });
                    },
                });
                this.groupedOverlays.rasters.layers['dealers'] = this.dealersLayer;
            }
            if (this.config.wsFiles) {
                const coveredKey = "Uncovered Population Potential (#)";
                this.whiteSpotsLayers = [];
                this.groupedOverlays.ws = {exclusive: true, layers: {}};
                const loadingLayers = this.config.wsFiles.map(async (wsFile, index) => {
                    const whiteSpots = await import(`@/assets/layers/${wsFile}`);
                    const radius = parseInt(wsFile.match(/\d+/)[0]);
                    const maxCovered = Math.max(...whiteSpots.features.map(feat => feat.properties[coveredKey]));
                    const maxWri = Math.max(...whiteSpots.features.map(feat => feat.properties['rwi']));
                    const minWri = Math.min(...whiteSpots.features.map(feat => feat.properties['rwi']));
                    const layer = L.geoJSON(whiteSpots, { pointToLayer: (feat, latlng) => {
                        const style = styles['ws'];
                        const color = interpolateBlues((feat.properties['rwi'] - minWri)/(maxWri - minWri));
                        style.fillColor = color;
                        const marker = L.circleMarker(latlng, {
                            ...styles['ws'], 
                            radius: (feat.properties[coveredKey] / maxCovered) * maxCircleRadius
                        }).bindPopup(() => {
                            const name = feat.properties['Site Name'];
                            const props = {...feat.properties};
                            delete props['Site Name'];
                            delete props['Site ID'];
                            this.setInfos(props, name);
                            return this.infos;
                        });
                        marker.on('popupopen', () => {
                            marker.areaMarker = L.circle(latlng, {radius: radius * 1000}).addTo(this.map);
                        });
                        marker.on('popupclose', () => {
                            marker.areaMarker.removeFrom(this.map);
                        });
                        return marker;
                    }});
                    this.groupedOverlays.ws.layers[`ws_${radius}km`] = layer;
                });
                await Promise.all(loadingLayers);
                this.groupedOverlays.ws.layers['none'] = emptyGeoJson;
                this.map.addLayer(emptyGeoJson);
            }
            await this.getCategories();
            this.segment = this.segments[0];
            this.isInit = true;
            this.$nextTick(() => { disablePropagation(this.$refs.map_ui); })
        },
        startIntro(override) { 
            this.tourTuto.start(override, this.translate); 
        },
        explanations() {
            this.$modal.show('explanations');
        },
        countries() {
            this.$router.push('countries');
        },
        logout() { this.$logout(); },
        onMapClick(clickEvent) {
            const lat = clickEvent.latlng.lat;
            const lng = clickEvent.latlng.lng;
        },

        setInfos(data, title = '') {
            this.infoValues = data;
            this.infoTitle = title;
            return this.infos;
        },
        boundsParams() {
            const bounds = this.map.getBounds();
            const zoom = this.map.getZoom();
            return new URLSearchParams({
                left:   bounds.getWest(),
                right:  bounds.getEast(),
                top:    bounds.getNorth(),
                bottom: bounds.getSouth(),
                ccode: this.countryCode,
                zoom: zoom,
            });

        },
        getKeyIndicators() {
            const bounds = new URLSearchParams(this.boundsParams());
            this.popIndicLoading = true;
            fetch(`/api/views/key_figures?${bounds}`)
            .then(result => result.json())
            .then(response => {
                this.rasterStats = response.raster_stats;
                this.badgeLabels = Object.keys(this.rasterStats).filter(item => item !== totalKey);
            }).catch( error => {
                this.handleError(error);
            })
            .finally(() => {this.popIndicLoading = false});
        },

        getPois(segment, oldSegment) {
            let layer = this.segmentPoisLayers[segment];
            this.map.spin(true, {scale: 2});
            if (oldSegment) this.map.removeLayer(this.segmentPoisLayers[oldSegment]);
            if (layer) {
                this.map.addLayer(layer);
                this.countTargets();
                this.filterTargets();
                return;
            }
            const params = new URLSearchParams({segment: segment, ccode: this.countryCode});
            this.loading = true;
            fetch(`/api/views/pois?${params}`)
            .then(result => result.arrayBuffer())
            .then(response => {
                const points = new DataView(response);
                const poisLayer = new PruneClusterForLeaflet();
                let offset = 0;
                let maxSpeedMobile = 0; 
                let maxSpeedFixed = 0; 
                while (offset < response.byteLength) {
                    const gid = points.getUint32(offset);   offset += 4;
                    const lng = points.getFloat32(offset);  offset += 4;
                    const lat = points.getFloat32(offset);  offset += 4;
                    const covegages =   points.getUint8(offset++);
                    const category  =   points.getUint8(offset++);
                    let mobileSpeed   =   points.getUint8(offset++); 
                    let fixedSpeed    =   points.getUint8(offset++);
                    if (!this.config.isSpeedUnitMBit) {
                        mobileSpeed *= 8; fixedSpeed *= 8; // convert byte to bit
                    }
                    maxSpeedMobile = Math.max(maxSpeedMobile, mobileSpeed);
                    maxSpeedFixed = Math.max(maxSpeedFixed, fixedSpeed);
                    const marker = new PruneCluster.Marker(lng, lat);
                    marker.data = {
                        id : gid,
                        cov: covegages,
                        category: category,
                        mobileSpeed: mobileSpeed,
                        fixedSpeed: fixedSpeed,
                    };
                    poisLayer.RegisterMarker(marker);
                }
                this.maxSpeedMobile = maxSpeedMobile;
                this.maxSpeedFixed = maxSpeedFixed;
                poisLayer.PrepareLeafletMarker = (marker, data) => {
                    marker.setIcon(targetMarker);
                    marker.bindPopup(() => {
                        this.getPopupInfos('/api/views/poi_infos', data.id, 'name').then(content => {
                            const popup = marker.getPopup();
                            popup.setContent(content.outerHTML);
                            popup.update();
                        });
                        return 'Loading...'
                    }, {maxWidth: "auto"});
                };
                poisLayer.BuildLeafletClusterIcon = function(cluster) {
                    const population = cluster.population; // the number of markers inside the cluster
                    return new L.DivIcon({
                        html: `<div><span class="icon"></span> <span><b> ${population}</b></span> </div>`,
                        className: 'prunecluster targets',
                        iconSize: L.Point(75, 30)
                    });
                };

                poisLayer.on('add', () => this.map.spin(false));
                this.map.addLayer(poisLayer);
                this.segmentPoisLayers[segment] = poisLayer;
                this.filterTargets();
            }).catch(error => this.handleError(error))
            .finally(() => {
                this.map.spin(false);
                this.loading = false;
                this.countTargets();
            });
        },

        getPopupInfos(route, id, title) {
            const params = new URLSearchParams({id: id, ccode: this.countryCode})
            return fetch(`${route}?${params}`)
            .then(rep => rep.json())
            .then(response => {
                const data = response;
                let titleStr = this.$t(title);
                if (title in data) {
                    titleStr = data[title];
                    delete data[title];
                }
                if (data['yls_description']) {
                    data['short_desc'] = data['yls_description']
                    delete data['yls_description'];
                }
                this.setInfos(data, titleStr);
                return this.infos;
            });
        },

        objtoChartData(obj) {
            return Object.entries(obj)
                .sort((a, b) => b[1] - a[1]).slice(0, 10)
                .sort((a, b) => a[1] - b[1])
                .reduce((obj, [plan, nb]) => {
                    obj['labels'] = obj['labels'].concat(plan)
                    obj['series'] = obj['series'].concat(nb)
                    return obj;
                }, defaultDict([]));
        },
        drawHistograms() {
            const planTypesData =   this.objtoChartData(this.customerPlans);
            const categoriesData =  this.objtoChartData(this.displayedCategories);
            const options = {...chartOptions};
            this.$nextTick(() => {
                options.axisY.offset = 130;
                if (planTypesData.labels.length)
                    new Chartist.Bar('#widget-plans', planTypesData, options);
                options.axisY.offset = 70;
                if (categoriesData.labels.length)
                    new Chartist.Bar('#widget-categories', categoriesData, options);
            });
        },

        keyIndicCoverages(inputObj) { 
            const obj = {...inputObj};
            delete obj.total;
            return obj;
        },

        async filterTargets() {
            await nextTick();
            this.displayedPois = 0;
            const labelId = this.filterStr[this.filterLabels.indexOf(this.filterValue)];
            const maskFunc = this.maskFuncs[labelId];
            const poisLayer = this.segmentPoisLayers[this.segment];
            poisLayer.Cluster._markers.forEach( marker => {
                let match = maskFunc(marker.data.cov);
                if (this.advancedActivated) {
                    if (marker.data.mobileSpeed && this.filterableByMobileSpeed ) {
                        match ||= (marker.data.mobileSpeed >= this.mobileSpeedFilter[0]) && (marker.data.mobileSpeed <= this.mobileSpeedFilter[1]);
                    }
                    if (marker.data.fixedSpeed && this.filterableByFixedSpeed) {
                        match ||= (marker.data.fixedSpeed >= this.fixedSpeedFilter[0]) && (marker.data.fixedSpeed <= this.fixedSpeedFilter[1]);
                    }
                }
                if (match) this.displayedPois += 1;
                marker.filtered = !match;
            });
            poisLayer.ProcessView();
        },
        async countTargets(){
            const poisLayer = this.segmentPoisLayers[this.segment];
            if (!poisLayer) return;
            const bounds = this.map.getBounds();
            let poisStats = {'outside3g': 0, 'outside4g': 0, 'outside_wlfixed': 0, 'outside_fixed': 0, 'target': 0};
            let total = 0;
            await Promise.resolve(this.categories);
            this.displayedCategories = defaultDict(0);
            poisLayer.Cluster._markers.forEach( marker => {
                if (!bounds.contains(marker.position)) return;
                total += 1;
                this.displayedCategories[this.categories[marker.data.category]] += 1
                Object.keys(poisStats).forEach(key => {
                    if (this.maskFuncs[key](marker.data.cov)) 
                        poisStats[key] += 1;
                });
            });
            Object.keys(poisStats).forEach(key => {
                if (!this.maskLabelMapping.hasOwnProperty(key)) return;
                poisStats[this.maskLabelMapping[key]] = poisStats[key];
                delete poisStats[key];
            });
            poisStats[totalKey] = total;
            this.poisStats = poisStats;
            this.computeCustomerPlans();
        },

        computeCustomerPlans() {
            this.customerPlans = defaultDict(0);
            const bounds = this.map.getBounds();
            this.customersCluster.Cluster._markers.forEach(marker => {
                if (!bounds.contains(marker.position)) return;
                this.customerPlans[marker.data.service] += 1;
            });
            this.drawHistograms();
        },

        
        handleError(error) {
            console.log(error);
        },

        getCategories() {
            const params = new URLSearchParams({ccode: this.countryCode});
            return fetch(`/api/views/categories?${params}`)
            .then(rep => rep.json())
            .then(response => {
                this.categories = response.categories;
                return this.categories;
            });
        },

        getCustomers() {
            const params = new URLSearchParams({ccode: this.countryCode});
            return fetch(`/api/views/customers?${params}`)
            .then(rep => rep.json())
            .then(response => {
                this.customersCluster = new PruneClusterForLeaflet();
                response.customers.forEach(arr => {
                    const marker = new PruneCluster.Marker(arr[1], arr[0]);
                    marker.data = { id : arr[2], service: arr[3] };
                    this.customersCluster.RegisterMarker(marker);
                });
                this.customersCluster.PrepareLeafletMarker = (marker, data) => {
                    marker.setIcon(userMarker);
                    marker.bindPopup(() => {
                        this.getPopupInfos('/api/views/customer_infos', data.id, 'customer').then(content => {
                            const popup = marker.getPopup();
                            popup.setContent(content.outerHTML);
                            popup.update();
                        });
                        return 'Loading...'
                    }, {maxWidth: "auto"});
                };
                this.customersCluster.BuildLeafletClusterIcon = function(cluster) {
                    const population = cluster.population; // the number of markers inside the cluster
                    return new L.DivIcon({
                        html: `<div><span class="icon"></span> <span><b> ${population}</b></span> </div>`,
                        className: 'customers',
                        iconSize: L.Point(75, 30)
                    });
                };
            });
        },

        getAdm() {
            const params = new URLSearchParams({ccode: this.countryCode });
            return fetch(`/api/views/adm?${params}`)
            .then( rep => rep.json())
            .then(response => {
                this.admLayer = L.geoJSON(response, {style: styles['adm']});
                this.colorAdm(this.admSelections[this.admColorSelected]);
                this.admLayer.bindPopup('Loading...');
                this.admLayer.on('click', (e) => {
                    const popup = e.target.getPopup();
                    this.getPopupInfos('/api/views/adm_infos', e.layer.feature.id, this.admConfig.featureTitle).then(content => {
                        popup.setContent(content);
                        popup.update();
                    });
                });
            });
        },

        colorAdm(key) {
            const steps = this.colorLayer(this.admLayer, key);
            let format = '.0%';
            if (key == 'pop') format = ',.0f';
            else if (key == 'rwi') format = '.3f';
            this.availableLegends[0] = stepsToLegend(steps, key, key, format);
            if (this.legend) this.legend = this.availableLegends[0];
        },

        colorLayer(layer, key) {
            const values = [];
            layer.eachLayer(path => {
                values.push(path.feature.properties[key]);
            });
            const steps = getSteps(values, key);
            layer.eachLayer(path => {
                path.setStyle(getStyle(path, steps, key, key));
            });
            return steps;
        },

        updateLegend(groupName, layerName, type) {
            if (layerName.includes("ws") && type == "add") {
                this.legendWs = circleLegend;
                this.legendRwi = rwiLegend;
            }
            else if (layerName == 'none' && type === "add") {
                this.legendWs = null;
                this.legendRwi = null;
            }
            if (layerName != 'wards') return;
            if (type == 'remove') this.legend = null;
            else this.legend = this.availableLegends[0];
        },

        logRangeOrValue(toTransform, maxRef, format = false) {
            let formatFunc = format ? this.formatNumber : (val) => val;
            const isRange = Array.isArray(toTransform);
            const max = Math.log(maxRef);
            const scale = max / 100;
            if (!isRange) return toTransform == 0 ? 0 : formatFunc(Math.exp(scale * toTransform));
            return [
                toTransform[0] == 0 ? 0 : formatFunc(Math.exp(scale * toTransform[0])),
                formatFunc(Math.exp(scale * toTransform[1]))
            ]
        },
        exportQuery() {
            const bounds = this.boundsParams();
            this.map.spin(true, {scale: 2});
            this.loading = true;
            const params = new URLSearchParams(bounds);
            downloadFile(`/api/export/export_bbox?${bounds}`).finally(() => {
                this.loading = false;
                this.map.spin(false)
            });
        },
        defineMasks(maskConfig) {
            this.masks = maskConfig.boolValues.reduce((acc, cur, index) => {
                acc[cur] = 1 << index;
                return acc;
            }, {});
            Object.entries(maskConfig.maskDefs).forEach( ([maskName, conditions]) => {
                this.masks[maskName] = conditions.reduce((curMask, condition) => {
                    curMask |= this.masks[condition];
                    return curMask;
                }, 0);
            });
            this.masks['filter-all'] = 0;
            const mask3g = (vals) =>  (vals & this.masks['outside-3g']) == 0;
            // returns true if point inside poor 3G coverage: is not in 4G AND has bad 3G (not in 3G or in fringe/high tri)
            const maskPoor3g = (vals) => (( (vals & this.masks['outside-4g']) == 0) && (( (vals & this.masks['poor-3g']) != 0) || ( (vals & this.masks['3g_cov']) == 0 )));
            // returns true if point outside 4G
            const mask4g = (vals) => (vals & this.masks['outside-4g']) == 0;
            // returns true if point inside poor 4G coverage: not in fixed AND has bad 4G (not in 4G or in fringe/high tri)
            const maskPoor4g = (vals) => ( ( (vals & this.masks['mask-fixed']) == 0) && (( (vals & this.masks['poor-4g']) != 0) || ( (vals & this.masks['4g_5g_cov']) == 0 )) );
            this.maskFuncs = {
                outside3g: mask3g,
                poor3g: maskPoor3g,
                outside4g: mask4g,
                poor4g: maskPoor4g,
                outside_wlfixed: (vals) => (vals & this.masks['fixed_wl_c']) == 0,
                outside_fixed: (vals) => (vals & this.masks['fixed_cov']) == 0,
                target: (vals) => (vals & this.masks['is_target']) != 0,
                filter_all: () => true
            };
            this.maskLabelMapping = {
                'outside3g': '3g_sum',
                'outside4g': '4g_sum',
                'target': 'matching',
                'outside_fixed': 'fixed_sum',
                'outside_wlfixed': 'fixedwl_sum',
            }
        }

    },
    computed: {
        infos() { return this.$refs.infos.$el; },
        filterLabels() { return this.filterStr.map(s => this.$t(s)); },
        fixedSpeedFilter() {  return    this.logRangeOrValue(this.fixedSpeedFilterPct,  this.maxSpeedFixed); },
        mobileSpeedFilter() { return    this.logRangeOrValue(this.mobileSpeedFilterPct, this.maxSpeedMobile); },
        filterableByMobileSpeed() { return this.speedFilterConfig.mobile !== undefined; },
        filterableByFixedSpeed() { return this.speedFilterConfig.fixed !== undefined; },
    },
    watch: {
        covSelected(now, before) {
            this.map.removeLayer(this.coverageLayers[before]);
            this.map.addLayer(this.coverageLayers[now]);
        },
        covOpacity(now) {
            this.coverageLayers.forEach(l => l.setOpacity(now/100));
        },
        segment(now, old) {
            this.getPois(now, old);
        },
        admColorSelected(now) {
            this.colorAdm(this.admSelections[now]);
        },
        admColorSelected(now) {
            this.colorAdm(this.admSelections[now]);
        },
        showPois(now) {
            const layer = this.segmentPoisLayers[this.segment];
           if (now === true) layer.addTo(this.map); 
           else layer.removeFrom(this.map);
        }
        
    },
    // depending on browser, blur sometimes do not occur
    beforeRouteLeave (to, from, next) {
        this.$el.blur();
        next();
    },
}
</script>
<style lang="scss" >
@import '@/style/scss/components/map'; 
</style>