<template>
    <div class="login-wrapper">
        <form @submit.prevent="submit">
            <p v-if="err"> {{ err }} </p>
            <p v-if="errors.length">
                <b>Please correct the following error(s):</b>
                <ul> <li v-for="error in errors" :key="error">{{ error }}</li> </ul>
            </p>
            <h2> Login </h2>
            <input type="text" v-model="username" placeholder="Username"/>
            <input type="password" v-model="password" placeholder="Password" />
            <button type="submit">Log in</button>
        </form>
    </div>
</template>

<script>
import { preparePost } from '@/util/util.js';

export default {
    name: 'login-view',
    data() {
        return {
            username: '',
            password: '',
            errors:[],
        }
    },
    props: ['err'],
    methods: {
        submit() {
            this.errors = [];
            if(!this.username) this.errors.push("Username required.");
            if(!this.password) this.errors.push("Password required.");
            if (this.errors.length) return;
            const [headers, body] = preparePost({username: this.username, password: this.password}, true);
            return fetch(`/api/auth/login`, {
                method: "POST",
                body: body,
                headers: headers,
            })
            .then(async rep => {
                if (rep.status == 500) {
                    throw 'Internal server error, please change the template or contact Masae Analytics if the error persists';
                }
                else if (rep.status != 200) {
                    throw await rep.text();
                }
                return rep.json();
            })
            .then( (response) => {
                localStorage.setItem('countries', response.countries);
                localStorage.setItem('username', response.username);
                this.$router.push('/countries')
            }).catch(error => {
                this.errors.push(error);
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/style/scss/components/login.scss";
</style>