export const introSteps = [
    { 
        intro: "tuto.intro",
    },
    {
        intro: 'tuto.tour',
    },
    {
        element: '.layer-selector',
        intro: "tuto.review_layers",
        onbeforechange: (elem) => { elem.classList.add('opened'); }
    },
    {
        element: '.layer-group',
        intro: "tuto.available_layers",
    },
    {
        element: '.controler-wrapper',
        intro: "tuto.ward_coloring",
    },
    {
        element: '.controler-wrapper:nth-child(2)',
        intro: "tuto.coverage",
    },
    {
        element: '.basemaps',
        intro: "tuto.basemaps",
        onbeforechange: () => { document.querySelector('.layer-selector').classList.remove('opened'); }
    },
    {
        element: '.key-indicators',
        intro: "tuto.key_indicators",
        position: 'right',
    },
    {
        element: '.filter-slider',
        intro: "tuto.filter_cov",
        position: 'right',
        onbeforechange: () => { document.querySelector('.advanced-filters').classList.add('opened'); }

    },
    {
        element: '.advanced-filters',
        intro: "tuto.filter_advanced",
        position: 'right',
    },
    {
        element: 'section.as-container--scrollable',
        intro: "tuto.histograms",
        position: 'right',
        onbeforechange: () => { document.querySelector('.advanced-filters').classList.remove('opened'); }
    },
    {
        element: '.segment',
        intro: "tuto.segment",
        position: 'right',
    },
    {
        intro: `tuto.over`,
    },
];

import introJs from 'intro.js';

const identity = (text) => text;
export class Tutorial {
    static defaultOptions = {
        hidePrev: false,
        showBullets: false,
        hideNext: false,
        keyboardNavigation: false,
        showStepNumbers: true,
        scrollToElement: true,
        skipLabel: 'Skip for now',
        tooltipClass: 'introjs-custom'
    };

    constructor(storageKey, steps, introJsOptions = null, onExit = null) {
        const useStorage = storageKey && storageKey.length > 0;
        if (useStorage) {
            this.value = localStorage.getItem(storageKey);
            if (!this.value) this.value = 0;
            else this.value = parseInt(this.value);
            // add and remove never again buttons for first steps
            if (steps.length > 1) {
                steps[0].onafterchange = () => { Tutorial.addButton(intro, storageKey, this.intro._options.doneLabel); }
                steps[1].onbeforechange = () => { Tutorial.removeButton(); }
            }
        }
        const intro = introJs();
        this.init = true;
        const mergedOptions = Object.assign({...Tutorial.defaultOptions}, introJsOptions);
        intro.setOptions({...mergedOptions , steps: steps});

        intro.onbeforechange(function(target) {
            const currentStep = intro._options.steps[intro._currentStep];
            if (currentStep.onbeforechange) currentStep.onbeforechange(target);
            // dynamically update element, because if added during tutorial, introJS won't find it
            intro._options.steps.forEach(function (step, key) {
                if(step.element) {
                    intro._introItems[key].element = document.querySelector(step.element);
                    if (step.modifier) intro._introItems[key].element = step.modifier(intro._introItems[key].element);
                    intro._introItems[key].position = step.position ? step.position : 'bottom';
                }
            });
            if(target && !target.classList.contains('introjsFloatingElement')) setTimeout(() => intro.refresh(), 0);
        });
        
        intro.onafterchange(()  => { 
            const currentStep = intro._options.steps[intro._currentStep];
            if (currentStep.onafterchange) currentStep.onafterchange();
        });
        
        // define what happens when user completes the tutorial
        intro.oncomplete(() => {
            if (useStorage) localStorage.setItem(storageKey, 4)
            this.init = false; // prevents onexit() override
        });
        
        intro.onexit(() => {
            if (this.init) { // prevent double call
                if (onExit) onExit();
                if (useStorage) localStorage.setItem(storageKey, this.value + 1)
                this.init = false;
            }
        });
        this.intro = intro;
    }
    
    // append never again button 
    static addButton(intro, key, label) {
        const buttons = document.querySelector('.introjs-tooltipbuttons');
            const btn = document.createElement("button");
            btn.id = "buttonNever";
            btn.style = 'margin-right: 5px;';
            const text = document.createTextNode(label);
            btn.appendChild(text);
            btn.classList.add('introjs-button',  'introjs-nextbutton');
            btn.addEventListener('click', () => {
                intro.exit();
                localStorage.setItem(key, 4);
            })
            const firstButton = buttons.children[0];
            buttons.insertBefore(btn, firstButton);
    }

    static removeButton() {
        const btn = document.getElementById("buttonNever");
        if (btn) btn.remove();
    }

    start(override, transform = identity) {
        if(!this.value || this.value < 4 || override) {
            // copy initial labels
            if (!this.intro.initialText) this.intro.initialText = this.intro._options.steps.map(step => step.intro);
            // translate steps
            this.intro._options.steps = this.intro.initialText.map((label, i) => {
                const step = this.intro._options.steps[i];
                let text;
                if (Array.isArray(label)) text = transform(label[0], label[1]);
                else text = transform(label);
                return {...step, intro: text};
            });
            // transform button labels
            if (transform != identity) {
                const labelOpts = {
                    skipLabel: transform('skip-now'),
                    doneLabel: transform('done'),
                    nextLabel: transform('next'),
                    prevLabel: transform('prev')
                }
                this.intro.setOptions(labelOpts);
            }
            this.init = true;
            this.intro.start();
        }
    }
}