<template>
    <div class="legend">
        <h3> {{ $t(title) }} </h3>
        <div class="legend-row" v-for="cat in data" v-bind:key="cat.label">  
            <span class="color-wrapper"> <span :class="legendClass(cat)" v-bind:style="{background: cat.color}"></span> </span>
            <label> {{cat.label}} </label>
        </div>
    </div>
</template>


<script>
// types = 'color', 'circle-big', 'circle-small'
export default {
    name: 'map-legend',
    props: {
        title: String,
        data: {
            type: Array,
            default: function() { [{label: "Empty", color: "#000000", type: 'color'}] },
            required: true,
            validator: function (values) {
                return values.length > 0 && values.every(v => v.label && v.color);
            }
        }
    },
    methods: {
        legendClass(row) {
            if (!row.type || row.type == 'color') return 'legend-color';
            return 'legend-color ' + row.type;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/scss/components/legend.scss";
</style>
