import * as d3 from 'd3-format';
import {colors, colorWs} from "@/style/layerStyles.js";
import {interpolateBlues} from 'd3-scale-chromatic';

// static legend declaration
const popLegend= {
    title: "Population",
    data: [
        {color: "#FFFFB2", label:"Less"},
        {color: "#FD8D3C", label:""},
        {color: "#BD0026", label:"More"},
    ]
};

const circleLegend = {
    title: 'ws',
    data: [
        {color: colorWs, label:"More potential", type:'circle-big'},
        {color: colorWs, label:"Less potential", type:'circle-small'},
    ]
};

const rwiLegend = {
    title: 'rwi',
    data: [
        {color: interpolateBlues(0.1), label:"Higher Wealth Index"},
        {color: interpolateBlues(0.9), label:"Lower Wealth Index"},
    ]
};

// returns a formatted legend object from a {label:color} type object
function namedToLegend(obj, name) {
    const legend = Object.entries(obj).map(([key, val]) => {
        return {color: val, label: key};
    });
    return {title: obj[name], data: legend};
}

// return interval steps from the given values
function getSteps(values, key) {
    if (values.length === 0) return null
    const nbSteps = (colors[key] || colors['adm']).length;
    const vals = [...new Set(values)]
    const intervalSize = Math.floor(vals.length/nbSteps);
    vals.sort((a, b) => a - b);
    return [...Array(nbSteps).keys()].map(i => vals[i * intervalSize]);
}

// returns a formatted legend object from provided steps
function stepsToLegend(steps, name, layerKey, format, suff = null) {
    const cols = colors[layerKey] || colors['adm'];
    const coloredSteps = steps.map((s, i) => [s, cols[i]]);
    const f = d3.format(format);
    const legend = [];
    for (let i = coloredSteps.length - 1; i >= 0; --i) {
        const cur = coloredSteps[i];
        if (i == coloredSteps.length - 1) {
            legend.push({color: cur[1], label: `> ${f(cur[0])}`});
            continue;
        }
        const prev = coloredSteps[i  + 1];
        if (!i) legend.push({color: cur[1], label: `< ${f(prev[0])}`});
        else legend.push({color: cur[1], label: `${f(cur[0])} - ${f(prev[0])}`});
    }
    if (suff) legend.forEach(entry => entry.label = entry.label + ' ' + suff);
    return {title: name, data: legend};
}



export {popLegend, circleLegend, rwiLegend, stepsToLegend, namedToLegend, getSteps};