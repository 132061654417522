<template>
    <div class="side-wrapper">
        <aside> 
            <slot name="left"> </slot>
        </aside>
        <main>
            <slot name="right"> </slot>
        </main>
    </div>
</template>

<script>

export default {
    name: 'split-view',
    data() {
        return { }
    },
};
</script>

<style lang="scss">
@import "@/style/scss/components/splitView.scss";
</style>