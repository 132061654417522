<template>
<div class="controler-wrapper">
    <p> {{ $t(title) }} </p>
    <div class="choices">
        <div  v-for="(item, index) in layers" :key="item">
            <div class="btn-radio">
                <input type="radio" :name="title" autocomplete="off" :id="item" :value="index" v-model="cov">
                <label :for="item"> {{ $t(item) }}</label>
            </div>
        </div>
    </div>
    <div v-if="layerOpacity != null" class="opacity">
        <span> {{ $t('layer_opacity') }} </span>
        <input type="range" min="0" max="100" v-model="opacity">
    </div>
</div>
</template>


<script>
export default {
    name: 'layer-controler',
    props: {
        title: String,
        layers: Array,
        layerSelected: Number,
        layerOpacity: {
            type: Number,
            default: null
        }
    },
    data: function() {
        return {
            currentLegends: [],
        }
    },
    computed: {
        cov: {
            get() { return this.layerSelected; },
            set(val) { this.$emit('update:layerSelected', val);}
        },
        opacity: {
            get() { return this.layerOpacity; },
            set(val) { this.$emit('update:layerOpacity', parseInt(val));}
        },
    }
}
</script>

<style lang="scss" scoped>
.controler-wrapper {
    p { 
        margin: 5px; 
        text-decoration: underline;
        font-weight: bold;
        text-align: center;
    }
    .choices {
        display: flex;
        flex-flow: row wrap;
        & > div {
            flex: 1 0 6rem;
        }
        justify-content: space-around;
    }
    .opacity {
        text-align: center;
        input[type="range"] {
            margin: 0 auto;
            display: block;
            height: 1.5rem; 
        }
    }
}

</style>