const za = require('@/locales/en-ZA.json');
import * as d3 from 'd3-format';
d3.formatDefaultLocale(za);

const formatCurrency = d3.format("$.1f");
const formatPercent = d3.format(".0%");
const formatNumber = (nbDecimal) => d3.format(`,.${nbDecimal}f`);
const formatBool = (b) => `<span class="boolean ${b ? "yes": "no"}"> </span>`; 
const formatLink = val => {
    if (val.indexOf && val.indexOf('http') > -1) return `<a href=${val} target=_blank> ${val} </a>`;
    return val;
};
const formatDecimal = d3.format(".2s");
const formatKm = val => {
    return formatDecimal(val * 1000) + 'm';
}
const mBytesToMbit = (val) => val == 0 ? 'Unkown' : `${val * 8} Mbit/s`;
const toMbit = (val) => val == 0 ? 'Unkown' : `${val} Mbit/s`;
const formatDate = (val) => val.substring(0, 10);

// variable_name => [format func, label_id (null for unchanged), icon, section (null if irrelevant)]
// the order matters, the resulting formatted object will be sorted
const valuesFormat = new Map([
    
    ['Uncovered Population Potential (#)',             [formatNumber(0), null, 'icon-users', 0]],
    ['pop',             [formatNumber(0), null, 'icon-users', 0]],
    ['last revenue month',  [formatDate, null, null, 0]],
    ['commission_date',     [formatDate, null, null, 0]],
    ['usage dt max',        [formatDate, null, null, 0]],
    ['category',        [null, null, null, 0]],
    ['subcategories',   [null, null, null, 0]],
    ['address',         [null, null, null, 0]],
    ['adm3_en_za',         [null, null, null, 0]],
    ['adm2_en_za',         [null, null, null, 0]],
    ['adm1_en_za',         [null, null, null, 0]],
    ['adm2_en_ng',         [null, null, null, 0]],
    ['adm1_en_ng',         [null, null, null, 0]],
    ['lga',             [null, null, null, 0]],
    ['state',           [null, null, null, 0]],
    ['isp',             [null, null, null, 0]],
    ['dist_isp',        [formatKm, null, null, 0]],
    ['is_target',       [formatBool, null, 'icon-target', 0]],
    ['low_tri',         [formatBool, null, 'icon-mountain', 0]],
    ['wifi',            [formatBool, null, 'icon-wifi', 0]],
    ['fixed_cov',       [formatBool, null, 'icon-ethernet', 0]],
    ['fixed_wl_c',      [formatBool, null, 'icon-signal', 0]],
    ['fixed_wl_cov',    [formatBool, null, 'icon-signal', 0]],
    ['s4g_cov',         [formatBool, null, 'icon-signal', 0]],
    ['s4g_5g_cov',      [formatBool, null, 'icon-signal', 0]],
    ['4g_5g_cov',       [formatBool, null, 'icon-signal', 0]],
    ['s3g_cov',         [formatBool, null, 'icon-signal', 0]],
    ['3g_cov',          [formatBool, null, 'icon-signal', 0]],
    ['cell_wifi',       [formatBool, null, 'icon-wifi', 0]],
    ['price_zar',       [formatCurrency, null, 'icon-money', 0]],
    ['rwi',             [formatNumber(3), null, null, 0]],
    ['population',      [formatNumber(0), null, 'icon-users', 0]],
    ['pop_uncov_3g',    [formatPercent, null, 'icon-signal', 0]],
    ['pop_uncov_4g',    [formatPercent, null, 'icon-signal', 0]],
    ['pop_uncov_fw',    [formatPercent, null, 'icon-signal', 0]],
    ['pop_uncov_f',     [formatPercent, null, 'icon-ethernet', 0]],
    ['pop_frustrated_users',    [formatPercent, null, 'icon-disappointed', 0]],
    ['fixed_speed_mbits',       [toMbit, null, 'icon-speed', 0]],
    ['mobile_speed_mbits',      [toMbit, null, 'icon-speed', 0]],
    ['fixed_speed',     [mBytesToMbit, null, 'icon-speed', 0]],
    ['mobile_speed',    [mBytesToMbit, null, 'icon-speed', 0]],
    ['link',            [formatLink, null, 'icon-link', 1]],
    ['website',         [formatLink, null, null, 1]],
    ['description',     [null, null, null, 1]],
    ['short_desc',      [null, null, null, 1]],
    ['yls_website',     [formatLink, null, null, 1]],
    ['phone_1',         [null, null, null, 1]],
    ['phone_2',         [null, null, null, 1]],
    ['headcount',       [null, null, null, 1]],
    ['yls_mobile_phone',[null, null, null, 1]],
    ['yls_phone',       [null, null, null, 1]],
]);
const orderedKeys = Array.from(valuesFormat.keys());

function constructFormatted(olabel, label, value, icon, section) {
    return {
        originalLabel: olabel,
        label: label,
        formatted: value,
        icon: icon,
        section: section
    }
}
/**
 * Format the given value according to the valuesFormat object, if the provided key exists
 * 
 * @param  {String} key     The key to search in valuesFormat
 * @param  {Any}    value   The value to format
 * @return {Object}         The formatted object
 */
function format(key, value) {
    if (!valuesFormat.has(key)) return constructFormatted(key, key, value, null, 0);
    const formats = valuesFormat.get(key);
    let formatted = value;
    let label = key;
    if (formats[0]) formatted = formats[0](formatted);
    if (formats[1]) label = formats[1];
    return constructFormatted(key, label, formatted, formats[2], formats[3]);
}

function formatObject(obj) {
    const formattedItems = Object.keys(obj).reduce( (prev,key) => {
        if (obj[key] == null) return prev;
        const formatted = format(key, obj[key]);
        prev.push(formatted);
        return prev;
    }, []);
    formattedItems.sort((a, b) => {
        return orderedKeys.indexOf(a.originalLabel) - orderedKeys.indexOf(b.originalLabel);
    });
    return formattedItems;

}


export {formatObject};