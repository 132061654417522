// https://stackoverflow.com/a/18234317
String.prototype.formatUnicorn = String.prototype.formatUnicorn || function () {
    let str = this.toString();
    if (arguments.length) {
        const t = typeof arguments[0];
        const args = ("string" === t || "number" === t) ?
            Array.prototype.slice.call(arguments)
            : arguments[0];

        for (const key in args) {
            str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }
    }

    return str;
};
const templateMarker = `
<svg style="overflow: visible" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 25 41">
    <image style="pointer-events: none; cursor: auto"  width="70" height="55" y="-14" x="-13" href="{shadowUrl}"/>
    <path d="M6.3 5.3h12.8v14.5H6.2z" fill="#ffffff" />
    <path d="{contourPath}" fill="{urlId0}" stroke="{urlId1}" stroke-width="1.1" stroke-linecap="round" />
    <image style="{whiteIconStyle}" class="inner-icon" x="3.45" y="4.2" width="18" height="18" {hrefIcon} />
</svg>`;

const gradientsTemplate = `
<linearGradient xlink:href="#{id0}" id="{id0}" gradientUnits="userSpaceOnUse" gradientTransform="translate(-32.9 -2.6)" x1="45.3" y1="41.3" x2="45.3" y2="3.7">
    <stop offset="0" stop-color="{botColor}"/>
    <stop offset="1" stop-color="{topColor}"/>
</linearGradient>
<linearGradient id="{id1}" gradientUnits="userSpaceOnUse" gradientTransform="translate(58.8 -2.6)" x1="-49.3" y1="22.8" x2="-49.3" y2="3.7">
    <stop offset="0" stop-color="{botColorDarkened}" />
    <stop offset="1" stop-color="{topColorDarkened}" />
</linearGradient>
`;

let nbMarker = 0;
const holePath = "m0 7.2a4.7 4.7 0 1 1 0 9.4 4.7 4.7 0 0 1 0-9.4z";
// use https://yqnn.github.io/svg-path-editor/ to add more shapes
const contourBasic = "M12.6 1.3C6 1.3.6 7 .6 13.3c0 2.7 1.6 6.2 2.7 8.6l9.3 18 9.3-18c1-2.4 2.7-5.8 2.7-8.7 0-6.2-5.5-11.9-12-11.9z";
const coutourSquared = "m13 1c-13 0-12-1-12 12c0 15-1 13 7 13.1l4.9 13.8 5-13.9c8.1 0 7.1 1 7.1-13c0-13 1-12-12-12z";
const defaultBot = "#126fc6";
const defaultTop = "#4c9cd1";
const filterWhite = 'filter: brightness(0) invert(1);';
const shadowUrl = require('../assets/img/icons/marker-shadow.png');
import {adjustColor} from '@/util/colorUtil.js';
export default class CustomMarker {
    constructor(options) {
        this.options = options;
        const botColor = this.getBotColor();
        const topColor = this.getTopColor();
        const gradientArgs = {
            id0: this.getId(0),
            id1: this.getId(1),
            botColor: botColor,
            botColorDarkened: adjustColor(botColor, -10),
            topColor: topColor,
            topColorDarkened: adjustColor(topColor, -10),
        };
        const markerAgrs = {
            shadowUrl: shadowUrl,
            contourPath: this.getContourPath(),
            whiteIconStyle: this.options.whiteIcon ? filterWhite : '',
            hrefIcon: this.options.iconPath ? `href="${this.options.iconPath}"`: '',
            urlId0: this.getId(0, true),
            urlId1: this.getId(1, true),
        };
        const html = templateMarker.formatUnicorn(markerAgrs);
        const gradients = gradientsTemplate.formatUnicorn(gradientArgs);
        let svgDefs = document.getElementById('svg-definitions');
        svgDefs.insertAdjacentHTML('beforeend', gradients);
        nbMarker++;
        this.divIcon = new L.DivIcon({
            html: html,
            iconSize:    [26, 44],
            iconAnchor:  [13, 44],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
        });
        return this.divIcon;
    }
    getId(idIndex, use) {
        const id = String.fromCharCode(97 + idIndex + (2 * nbMarker));
        if (use) return `url(#${id})`;
        return id;
    }
    getBotColor() {
        return this.options.colorBottom ? this.options.colorBottom : (this.options.color ? adjustColor(this.options.color, -30) : defaultBot);
    }
    getTopColor() {
        return this.options.colorTop ? this.options.colorTop : (this.options.color ? this.options.color : defaultTop)
    }
    getContourPath() {
        const contour = this.options.type == 'square' ? coutourSquared : contourBasic;
        return `${contour}${this.options.iconPath ? '': holePath}`;
    }
}