<template>
    <SplitView>
        <template #left>
            <div class="title">
                <h1> {{ $t('title')}} </h1>
                <h2> {{ $t('subtitle')}} </h2>
            </div>
        <img class="logo" alt="yahsat logo" src="@/assets/img/logo_yahsat_inverted.png">
        </template>
        <template #right>
            <component :is=rightComponent></component>
            <a href="https://www.masae-analytics.com" target='_blank'> <img class="masae" alt="Logo Masae" src="@/assets/img/logo_masae.png"> </a>
        </template>
    </SplitView>
</template>

<script>

import { preparePost } from '@/util/util.js';
import SplitView from '@/components/SplitView.vue';
import Login from '@/components/Login.vue';
import Countries from '@/components/Countries.vue';
import { splitViewTypes } from '@/util/common.js';

export default {
    name: 'initial-vue',
    components: { SplitView, Login },
    methods: {
    },
    computed: {
        rightComponent() {
            return this.$route.name === 'login' ? Login : Countries
        },
    }
};
</script>