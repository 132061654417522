import { createWebHistory, createRouter } from "vue-router";
import InitialVue from '@/views/InitialVue.vue';
import NotFound from '@/views/NotFound.vue';
import Map from '@/views/Map.vue';
import config from '@/config.json';

const routes = [
    { path: '/', redirect: { name: 'login' }},
    {
        path: '/login',
        name: 'login',
        component: InitialVue,
    },
    {
        path: '/countries',
        name: 'countries',
        component: InitialVue,
    },
];

config.countries.map(code => {
    routes.push({
        path: `/${code}`,
        name: code,
        component: Map,
        props: { countryCode: code},
        beforeEnter: (to, from, next) => {
            next();
        },
    });
});

// non-matching routes all point to 404
routes.push({
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
});

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

function isAuthenticated() {
    const logged = localStorage.getItem('countries');
    return (logged && logged.length) > 1;
}

// verify if authenticated for flow (/login if not, /countries if logged in)
router.beforeEach((to, from, next) => {
    if (to.name !== 'login' && !isAuthenticated()) next({ name: 'login', params: {err: 'You must be logged in to continue' }});
    else if (to.name == 'login' && isAuthenticated()) next({ name: 'countries' });
    else next();
})

export default router;
