<template>
    <div class="layer-selector"> <component :is="'icon-layer'" :width="'2.7rem'"/>
        <div class="wrapper">
            <div class="layer-group" v-for="(section, sectionName) in sections" :key="section.title">
                <span> {{ $t(sectionName) }} </span>
                <div class="layer-select" v-for="(layer, layerName) in section.layers" :key="layerName">
                    <div v-if="layerName != separatorName">
                        <input :type="section.exclusive ? 'radio' : 'checkbox'" :value="layerName" :name="sectionName" :id="sectionName + layerName"
                            @change="toggleLayer($event, sectionName, layerName, layer)">
                        <label :for="sectionName + layerName"> {{ $t(layerName) }} </label>
                    </div>
                    <p v-else class="separator" > <span> {{ $t(layer) }} </span> </p>
                </div>
            </div>
            <slot> </slot>
        </div>
    </div>
</template>

<script>

export default {
    name: 'layer-selector',
    props: {
        sections: Object,
        map: Object,
    },
    created() {
        this.separatorName = 'separator';
    },
    mounted() {
        // handle already present layers and callbacks when added
        Object.entries(this.sections).forEach(([groupName, group]) => {
            Object.entries(group.layers).forEach(([layerName, layer]) => {
                if (layerName == this.separatorName) return;
                if (this.map.hasLayer(layer)) this.toggleTick(groupName, layerName, true);
                layer.on('add', () => this.toggleTick(groupName, layerName, true));
                layer.on('remove', () => this.toggleTick(groupName, layerName, false));
            });
        });
    },
    methods: {
        // called on input click 
        toggleLayer(e, groupName, layerName) {
            const checked = e.target.checked;
            const group = this.sections[groupName];
            const layer = group.layers[layerName];
            if (group.exclusive) {
                Object.entries(group.layers).forEach( ([curLayerName, curLayer]) => {
                    const hasLayer = this.map.hasLayer(curLayer);
                    if (layer == curLayer) {
                        if (group.removable && hasLayer) {
                            this.$emit('layer-change', groupName, curLayerName, 'remove');
                            this.toggleTick(groupName, curLayerName, false);
                            this.map.removeLayer(curLayer);
                        }
                        else if (!hasLayer) {
                            this.map.addLayer(curLayer);
                            this.$emit('layer-change', groupName, curLayerName, 'add');
                        }
                        return;
                    }
                    if (hasLayer) {
                        this.$emit('layer-change', groupName, curLayerName, 'remove');
                        this.map.removeLayer(curLayer);
                    }
                });
                return;
            }
            this.$emit('layer-change', groupName, layerName, checked ? 'add' : 'remove');
            if (checked && !this.map.hasLayer(layer) ) this.map.addLayer(layer);
            else if (!checked && this.map.hasLayer(layer)) this.map.removeLayer(layer);
        },
        // called by adding/removing a layer programaticaly
        toggleTick(groupName, layerName, value) {
            const el = document.getElementById(groupName + layerName);
            el.checked = value;
        },
    },
}
</script>

<style lang="scss" scoped>
.layer-selector {
    
    background-color: white;
    border: 2px solid rgba(0,0,0,.2);
    border-radius: 5px;
    padding: 0.5rem;
    max-width: 20rem;
    width: max-content;
    cursor: default;
    .wrapper {
        background-color: white;
        display: none;
    }
    &:hover, &.opened {
        svg { display: none; }
        width: max-content;
        height: auto;
        .wrapper {
            display: block;
        }
    }
}
.layer-group {
    margin-top: 0.5rem;
}
.layer-group > span {
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
}

.separator {
    width: 85%;
    margin: 0.9rem auto;
    border-bottom: 2px solid black;
    display: block;
    text-align: center;
    line-height: 0.05rem;
    span {
        background: white;
        padding: 0 0.5rem;
        font-weight: bold;
    }
}

.layer-select {
    text-align: left;
}

</style>