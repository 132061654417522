// Export useful function to disable propagation for elements above the map

import L from 'leaflet';
L.Control.Wrapper = L.Control.extend({
        version: '1.0.1',
        options: {
            position: 'topright',
            element: null,
        },
        onAdd: function () {
            return disablePropagation(this.options.element);
        },
        onRemove: function () {},
});

L.control.wrapper = function (options) {
    return new L.Control.Wrapper(options);
};

export function disablePropagation(element) {
    L.DomEvent.disableClickPropagation(element);
    /* Prevent right click event propagation to map */
    L.DomEvent.on(element, 'contextmenu', function (ev)
    {
        L.DomEvent.stopPropagation(ev);
    });
    /* Prevent scroll events propagation to map when cursor on the div */
    L.DomEvent.disableScrollPropagation(element);
    return element;
}