
const grey = "#838383";
const beamColor = "#008000";
const colorWs = "#200fff";
const colors = {
    'adm': ["#f6f9a8", "#FFE724", "#FFBD2A", "#FF802A", "#FF210C"],
    'rwi': ["#a6f6f1", "#8dcaf4", "#6484f8", "#444dfb", "#200fff"],
};

// retrieves a color from the given value and steps
function getColor(value, steps, key) {
    const cols = colors[key] || colors['adm'];
    for (let i = steps.length - 1; i >= 0; i--) {
        if (value >= steps[i]) return cols[i]
    }
    return cols[0]
}

// retrieves a path style configuration
function getStyle(path, steps, propKey, layerKey) {
    const prop = path.feature.properties;
    const color = getColor(prop[propKey], steps, layerKey);
    const style = styles[layerKey] || styles['adm'];
    style.fillColor = color;
    return style;
}

const pointStyles = {
    
};

const onEachFeat = {

};

const styles = {
    "adm": {
        "color": grey,
        "weight": 1,
        "opacity": 1,
        "fill" : true,
        "fillOpacity" : 1,
    },
    "beams": {
        "color": beamColor,
        "weight": 1,
        "opacity": 1,
        "fill" : false,
    },
    "ws": {
        "weight": 1,
        "opacity": 1,
        "fillOpacity": 1,
        "fill" : true,
    }

}



export {styles, pointStyles, onEachFeat, colors, getStyle, colorWs};