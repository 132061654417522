export const trans = {
  "za": "South Africa",
  "ng": "Nigeria",
  "select-country": "Country selection",
  'title': "YahClick Geomarketing Platform",
  "subtitle": "Grasping the Africa opportunity",
  'key-indicators': 'Key indicators',
  'pois_stats': '<strong> {0} </strong> targets found out of <strong> {1} </strong>',
  'raster_stats': '<strong> {0} </strong> individuals',
  'loading-html': `<div class="spinner-bounce">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>`,
  layer_opacity: 'Network layer opacity',
  'none': 'None', 
  'top-plans': 'Top 10 plan type per number of customers',
  'top-categories': 'Top 10 categories',
  'categories': 'Category',
  'outside3g': 'Outside 3G', 
  'poor3g': 'Outside / poor 3G', 
  'outside4g': 'Outside 4G',
  'poor4g': 'Outside / poor 4G', 
  'filter_all': 'All',
  'filter-display': 'Coverage-based filter',
  'state': 'State',
  'lga': 'LGA',
  'ws': 'White Spots',
  'ws_60km': 'White Spots (60 km)',
  'ws_100km': 'White Spots (100 km)',
  'dealers': 'Yahsat Dealers',
  'dist_isp': 'Distance to nearest Yahsat dealer',
  'subcategories': 'Subcategories',
  'isp': 'Nearest Yahsat dealer',
  'adm4_pcode': 'Ward ID',
  'adm4_en_za': 'Ward ID',
  'adm3_en_za': 'Local Municipality',
  'adm2_en_za': 'Municipality',
  'adm1_en_za': 'Province',
  'adm1_en_ng': 'State',
  'adm2_en_ng': 'LGA',
  'category': 'Sub-category',
  '3g_cov': '3G coverage',
  '4g_5g_cov': '4G coverage',
  'fixed_cov': 'Fixed coverage',
  'ave_ds_fixed': 'Average download speed in area (Fixed)',
  'tri': 'Terrain Ruggedness Index',
  'high_tri': 'High Terrain Ruggedness',
  'low_tri': 'Low Terrain Ruggedness',
  'price': 'Price (Airbnb)',
  'wifi': 'Wifi (Airbnb)',
  'cell_wifi': 'Wifi cellular (Airbnb)',
  'wifi_speed': 'Wifi speed (Airbnb)',
  'yls_mobile_phone': 'Mobile Phone',
  'yls_phone': 'Phone',
  'phone_1': 'Phone',
  'yls_website': 'Website',
  '3g_cov': '3G coverage', 
  'f_3g_cov': 'Poor 3G coverage', 
  'f_4g_5g_cov': 'Poor 4G coverage',
  'fixed_wl_c': 'Fixed wireless coverage',
  'fixed_wl_cov': 'Fixed wireless coverage',
  'source': 'Source',
  'price_zar': 'Price',
  'link': 'Link',
  'address': 'Address',
  'wifi': 'Wifi',
  'main_cat': 'Main category',
  '3g_sum': '3G',
  '4g_sum': '4G',
  '3g': '3G', 
  '4g': '4G',
  'fixed': 'Fixed',
  'fixed_sum': 'Fixed',
  'fixedwl': 'Fixed Wireless',
  'fixedwl_sum': 'Fixed Wireless',
  'segment': 'Segment:',
  'tourism': 'Tourism', 
  'website': 'Website',
  'phone_2': 'Phone 2',
  'headcount': 'Headcount',
  'sme': 'SMEs',
  'pois': 'Points of Interest',
  description: 'Description',
  short_desc: 'Description',
  '3g_sum_tooltip': "Percentage of {0} without <br> <strong> @:{'3g_sum'} </strong> access",
  '4g_sum_tooltip': "Percentage of {0} without <br> <strong> @:{'4g_sum'} </strong> access",
  'fixed_sum_tooltip': "Percentage of {0} without <br> <strong> @:{'fixed'} </strong> access",
  'fixedwl_sum_tooltip': "Percentage of {0} without <br> <strong> @:{'fixedwl'} </strong> access",
  'rasters': 'Demographics & Customers',
  'pop': 'Population',
  'coverage-layers': 'Network coverage',
  'displayed-map': 'matching filter in whole country',
  'displayed-map-visible': 'displayed on current view',
  'public': 'Public services',
  'no-point-area': 'No point in current area',
  'customers': 'Yahsat clients',
  'account_no': 'Account number',
  'account_status': 'Account status',
  'allowance': 'Allowance',
  'beam_id': 'Beam ID',
  'commission_date': 'Commission date',
  'last revenue': 'Last revenue',
  'last revenue month': 'Last revenue month',
  'monthlyusage': 'Monthly usage',
  'service_offering': 'Service',
  'sp_code': 'SP code',
  'status': 'Status', 
  'usage dt max': 'Usage DT max',
  'population': 'Population',
  'pop_uncov_3g': 'No 3G',
  'pop_uncov_4g': 'No 4G',
  'pop_uncov_fw': 'No fixed wireless',
  'pop_uncov_f': 'No fixed wired',
  'beams': 'Beams',
  'wards': 'Wards',
  'adm-coloring': 'Wards coloring',
  "next": "Next &rarr;",
  "prev": "&larr; Back",
  "done": "Done",
  "skip-now": "Skip for now",
  pop_frustrated_users: 'Frustrated users',
  rwi: 'Wealth Index',
  customer: 'Customer',
  activate_advanced: "Measurements-based filter",
  export_pois: "Export all pois",
  export_no_pois: "Too many POIs to export: ommiting POIs",
  export: 'Export',
  advanced_filters: "Measurements-based filter",
  advanced_explain: "Add POIs with good coverage but in areas with bad speed check reports",
  filter_mobile_speed: "Filter by mobile speed", 
  filter_fixed_speed: "Filter by fixed speed", 
  s3g_cov: "Good 3G coverage",
  s4g_5g_cov: "Good 4G coverage",
  s4g_cov: "Good 4G coverage",
  is_target: "Is a target",
  fixed_speed_mbits: "Average download speed in area (Fixed)",
  mobile_speed_mbits: "Average download speed in area (Mobile)",
  fixed_speed: "Average download speed in area (Fixed)",
  mobile_speed: "Average download speed in area (Mobile)",
  tuto: {
    intro: '<h2> Yahsat geomarketing </h2> <p> Welcome to the online tutorial </p>',
    ward_coloring: 'Select the variable used to colour each ward (when the Wards layer is active)',
    available_layers: 'All the layers that can be added to the map are listed here',
    review_layers: "First, let's review the available layers",
    tour: 'This tour will help you discover the main features of the application',
    over: "This introduction is now over, but you can access it again by clicking the icon at the top-right of the screen.",
    coverage: "In this section, you can select the network technology you want to display",
    basemaps: "This menu lets you switch between several map backgrounds, giving some elements a better visibility",
    key_indicators: "Key indicators are computed for the current map view. Move the map or zoom in/out, and you will see them updated live.",
    filter_cov: "Select the coverage level for which you want to keep data point displayed (right is more)",
    filter_advanced: "It is also possible to further refine the filtering, defining a range of bandwidth based on Ookla data",
    segment: "Change the target segment here, and all the panels, statistics and map view are automatically updated",
    histograms: "Distributions of YahClick customers and potential targets by business category are computed live for the current map view",
  },
  help_t: 'Help',
  help_za: `
    <h1>Help</h1>
    <div class="help-content">
    <h2>Definitions</h2>
    <h3>Segment</h3>
    <ul>
      <li><strong>SMEs:</strong> Points of Interest (POIs) classified as "businesses" in Yellosa online directory</li>
    </ul>
    <ul>
      <li><strong>Tourism:</strong> POIs being Airbnb accomodations or classified as "Tourism & Accomodation" in Yellosa online directory</li>
    </ul>
    <ul>
      <li><strong>Public:</strong> POIs classified as "Public & Social Services" in Yellosa online directory</li>
    </ul>
    <h3>Poor coverage</h3>
    <p>POIs are considered as "poorly" covered either by 4G/5G or 3G if they either lie in a:</p>
    <ul>
      <li>1km rim at the edge of coverage</li>
    </ul>
    <ul>
      <li>Highly rugged zone (Terrain Ruggedness Index above 20)</li>
    </ul>
    <h3>Target</h3>
    <p>POIs are considered as targets if either:</p>
    <ul>
      <li>Their best connectivity solution is poor 4G coverage (no fixed, fixed wireless neither "good" 4G coverage)</li>
    </ul>
    <ul>
      <li>Maximum reported connection download speed in the last 12 months (mobile or fixed) in nearby area (500m x 500m square) is below 5mbps</li>
    </ul>
    <h2>Data Sources</h2>
    <h3><strong>Network Coverage</strong></h3>
    <ul>
      <li><strong>Fixed (incl. fixed wireless):</strong> Official coverage maps of CellC, MTN, Neotel/LiquidTelecom, Telkom, Vodacom (2021)</li>
    </ul>
    <ul>
      <li><strong>Mobile:</strong> Official 2G, 3G, 4G/LTE and 5G coverage maps of CellC, MTN, Rain, Telkom, Vodacom (2021)</li>
    </ul>
    <ul>
      <li><strong>Network performance:</strong> Average connection download speed reported by Ookla speedtests in the last 12 months per 500m x 500m grid cell, Ookla Network Performance Database (2021)</li>
    </ul>
    <h3>Socio-demographic</h3>
    <ul>
      <li><strong>Population:</strong> WorldPop, Estimated total number of people per grid-cell at a resolution of 3 arc seconds (approximately 100m at the Equator), adjusted to match the corresponding UNDP estimate (2020)</li>
    </ul>
    <ul>
      <li><strong>Wealth Index:</strong> Facebook Data For Good, Relative Wealth Index (RWI). Prediction of the relative standard of living at a 2.4km resolution using de-identified connectivity data, satellite imagery and other nontraditional data sources (2021)</li>
    </ul>
    <h3><strong>Points of interest</strong></h3>
    <ul>
      <li><strong>Businesses & Public Services:</strong> Yellosa.co.za business directory (comprehensively crawled on web, 2021)</li>
    </ul>
    <ul>
      <li><strong>Tourism:</strong> Yellosa.co.za business directory and Airbnb accomodations (comprehensively crawled on web, 2021)</li>
    </ul>
    <h3>Topographic</h3>
    <ul>
      <li><strong>Elevation:</strong> NASA SRTM Digital Elevation Model, 90m-resolution grid of ground altitude</li>
    </ul>
    <h3>Yahsat</h3>
    <ul>
      <li><strong>Customers:</strong> Provided by Vasco Fernandes on June 13th</li>
    </ul>
    <ul>
      <li><strong>Beams:</strong> Provided by Yahsat in 2017</li>
    </ul>
    </div>
  `,
  help_ng: `
  <h1>Help</h1>
    <div class="help-content">
    <h2 id="definitions">Definitions</h2>
    <h3 id="points-of-interest-pois-segments">Points of Interest (POIs)
    segments</h3>
    <ul>
    <li><strong>B2B - Other Organisations:</strong> Businesses classified as
    “Public &amp; Social Services” in Businesslist and private healthcare
    &amp; education sites</li>
    <li><strong>B2B - SMEs:</strong> Businesses not classified as “Public
    &amp; Social Services” in Businesslist</li>
    <li><strong>B2G - Healthcare &amp; Education:</strong> Public healthcare
    &amp; education sites</li>
    </ul>
    <h3 id="poor-coverage">Poor coverage</h3>
    <p>POIs are considered as “poorly” covered by mobile network if:</p>
    <ul>
    <li>(For Verizon-derived coverage) They lie in a 1km rim at the edge of
    coverage or in a highly rugged zone</li>
    <li>(For GSMA-derived coverage) The radio propagation modelling
    indicates that the radio signal at the POI’s location is weak</li>
    </ul>
    <h3 id="target">Target</h3>
    <p>POIs are considered as targets if either:</p>
    <ul>
    <li>Their best connectivity solution is <em>poor</em> 4G coverage</li>
    <li>Average mobile network download speed in nearby area (500m x 500m
    tile) in 2021 is below 5mbps</li>
    </ul>
    <h2 id="data-sources">Data Sources</h2>
    <h3 id="network-coverage"><strong>Network Coverage</strong></h3>
    <ul>
    <li><p><strong>Fixed:</strong> <a
    href="https://github.com/teamookla/ookla-open-data">Ookla Network
    Performance Database</a> (2021) &amp; Masae processing</p>
    <p>The location of Ookla fixed network speed connection tests were used
    to create a proxy for fixed coverage</p></li>
    <li><p><strong>Mobile:</strong> <a
    href="https://www.mobilecoveragemaps.com/">GSMA Mobile Coverage Maps</a>
    (2019), <a href="https://verizon.cellmaps.com/">Verizon Wireless</a>
    (2022) &amp; Masae processing</p></li>
    <li><p><strong>Network performance:</strong> Average connection download
    speed (mobile &amp; fixed network) reported by Ookla speedtests in 2021
    months per 500m x 500m grid cell, <a
    href="https://github.com/teamookla/ookla-open-data">Ookla Network
    Performance Database</a> (2021)</p></li>
    </ul>
    <h3 id="socio-demographic">Socio-demographic</h3>
    <ul>
    <li><strong>Population:</strong> <a
    href="https://data.grid3.org/maps/GRID3::grid3-nigeria-gridded-population-estimates-version-2-0/about">Bottom-up
    gridded population estimates for Nigeria, version 2.0</a>, at a spatial
    resolution of 3 arc-seconds (approximately 100m at the Equator), GRID3
    (2021)</li>
    <li><strong>Wealth Index:</strong> <a
    href="https://dataforgood.facebook.com/dfg/tools/relative-wealth-index">Relative
    Wealth Index</a> (RWI): Prediction of the relative standard of living at
    a 2.4km resolution using de-identified connectivity data, satellite
    imagery and other nontraditional data sources, Facebook Data For Good
    (2021)</li>
    </ul>
    <h3 id="points-of-interest"><strong>Points of interest</strong></h3>
    <ul>
    <li><strong>Businesses &amp; other private organisations:</strong> <a
    href="http://Businesslist.com.ng">Businesslist.com.ng</a> business
    directory (comprehensively crawled on web, 2022)</li>
    <li><strong>Healthcare &amp; education sites:</strong> <a
    href="https://grid3.gov.ng/">GRID3 Nigeria</a> (2022)</li>
    </ul>
    <h3 id="topographic">Topographic</h3>
    <ul>
    <li><strong>Elevation:</strong> NASA SRTM Digital Elevation Model,
    90m-resolution grid of ground altitude</li>
    </ul>
    <h3 id="yahsat">Yahsat</h3>
    <ul>
    <li><strong>Customers:</strong> Provided by Vasco Fernandes on February
    11th, 2022</li>
    <li><strong>Dealers:</strong> Provided by Eleanor Kathleen Potter on
    February 22nd, 2022</li>
    </ul>
    </div>
  `

}
