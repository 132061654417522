<template>
    
<div class="wrapper">
    <div v-for="key in keys" :key=key> 
        <span :content="$t(key + '_tooltip', [targetType])" class="as-badge colored" v-tippy="{allowHTML: true}">
            <component :is="'icon-forbidden'"/> {{ $t(key) }} </span>
        <span :content="`${nbFormat(values[key])} ${individualType}`" class="as-badge" v-tippy> {{ inPct[key] }} </span>
    </div>
</div>
</template>

<script>
import * as d3 from 'd3-format';
const percFormat = d3.format(".0%");
import {totalKey} from '@/util/common.js';

export default {
    name: 'badge-infos',
    beforeCreate() {
        this.nbFormat = d3.format(",.0f");
    },
    props: {
        values: Object,
        keys: Array,
        targetType: String,
        individualType: {
            type: String,
            default: () => {
                return this.targetType
            }
        }
    },
    computed: {
        inPct() {
            const tot = this.values[totalKey] ? this.values[totalKey] : 1;
            return Object.keys(this.values).reduce((obj, cur) => {
                if (cur == totalKey) obj[cur] = tot;
                else obj[cur] = percFormat(this.values[cur] / tot);
                return obj;
            }, {});
        }
    }
}
</script>
<style lang="scss" scoped>
.wrapper {
    display: flex;
    justify-content: space-evenly;
    > div {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }
    .as-badge.colored {
        color: white;
        background-color: #8a8a8a;
        & > svg {
            margin-right: 5px;
        }
    }
}
</style>