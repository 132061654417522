export function preparePost(args = null, isJson = false) {
    const headers = new Headers({});
    if (isJson) {
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
    }
    if (args && isJson) return [headers, JSON.stringify(args)];
    const data = new FormData();
    if (args) {
        Object.entries(args).forEach(([key, value]) => {
            data.append(key, value);
        });
    }
    return [headers, data];
}


export function downloadFile(url) {
    return fetch(url).then(response =>  {
        if (response.status == 500) {
            window.alert('Internal server error, please change the template or contact Masae Analytics if the error persists');
            return;
        }
        else if (response.status != 200) {
            return response.text().then(txt => window.alert(txt));
        }
        const filename = response.headers.get('Content-Disposition').split("filename=")[1].replace(/"/g, '');
        const type = response.headers.get('Content-Type');
        response.blob().then( blob => {
            const url = window.URL.createObjectURL(new Blob([blob], {type: type}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            link.click();
            window.URL.revokeObjectURL(url);
        });
    })
}