import { createApp, h } from 'vue';
import App from './App.vue';
import router from './router.js'
import VueTippy from "vue-tippy";

import Toaster from "@meforma/vue-toaster"; 

import i18n from './i18n';
import { vfmPlugin } from 'vue-final-modal';
import { defineCustomElements } from '@carto/airship-components/dist/loader';

import config from '@/config.json';
import './style/scss/global.scss';

document.title = config["platform_name"];
const app = createApp(App);

// register global function for plausible events
if (window.plausible) {
    app.config.globalProperties.$plausible = (window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) })
}
else app.config.globalProperties.$plausible = function () {};

defineCustomElements(window);

const svgTemplate = require.context('@/assets/img/icons/inline/.?inline', false, /\.svg$/);
svgTemplate.keys().map(async key => {
    const name = key.match(/[a-zA-Z-_]+/)[0]; // remove extension
    const svg = svgTemplate(key);
    const elem = document.createElement('div');
    elem.innerHTML = svg;
    const svgAttributes = {};
    Array.from(elem.firstChild.attributes).forEach(att => {
        svgAttributes[att.nodeName] = att.nodeValue;
    });
    app.component('icon-' + name, {
        render() {
            return h('svg', 
            {
                ...svgAttributes,
                class: 'svg-icon',
                style: {
                    width: this.width,
                    height: this.height,
                    fill: this.fillColor,
                    stroke: this.strokeColor,
                },
                innerHTML: elem.firstChild.innerHTML
            },)
        },
        props: {
            width: {
              type: String,
              default: '1rem'
            },
            height: {
                type: String,
                default: null
            },
            fillColor: {
                type: String,
                default: 'currentColor'
            },
            strokeColor: {
                type: String,
                default: 'none'
            },
        }
    });
});

app.config.globalProperties.$config = config;
app.config.globalProperties.$logout = () => fetch('/api/auth/logout')
    .then(result => result.text())
    .then(() => {
        localStorage.removeItem("countries");
        localStorage.removeItem("username");
        router.push('/login');
    });

app.use(i18n);
app.use(vfmPlugin({
    key: '$modal',
}));
      

app.use(VueTippy, {
  arrow : true, 
  arrowType : "round",
  boundary: 'viewport'
});
// Vue.component("tippy", TippyComponent);

app.use(Toaster, {position: 'bottom', duration : 3000});

app.use(router).mount('#app');
