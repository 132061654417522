import L from 'leaflet';
const token ="pk.eyJ1IjoibWFzYWUiLCJhIjoiY2w1YXVnYXR0MDFiNTNibXM2enR1aGF0MiJ9.268qAH7oilHyeEj2dkACnw";
const baseLayers = [
    L.tileLayer('//api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            id: 'masae/ckk5l1n550n7z17ntio9gt0u4',
            attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="http://www.openstreetmap.org/about/" target="_blank">© OpenStreetMap</a>',
            tileSize: 512,
            maxZoom: 19,
            zoomOffset: -1,
            accessToken: token,
            label: 'Terrain'
    }),
    L.tileLayer('//api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            id: 'masae/ckk5lxdlc18gd18nxm44wt1ef',
            attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="http://www.openstreetmap.org/about/" target="_blank">© OpenStreetMap</a>',
            tileSize: 512,
            maxZoom: 19,
            zoomOffset: -1,
            accessToken: token,
            label: 'Light'
    }),
    L.tileLayer('//api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            id: 'masae/ckk5l9hk216ae17ryocwa4tva',
            attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="http://www.openstreetmap.org/about/" target="_blank">© OpenStreetMap</a>',
            tileSize: 512,
            maxZoom: 19,
            zoomOffset: -1,
            accessToken: token,
            label: 'Streets'
    }),
    L.tileLayer('//api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            id: 'masae/ckk5lauvu0ti417p5t7k70l62',
            attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="http://www.openstreetmap.org/about/" target="_blank">© OpenStreetMap</a> <a href="https://www.maxar.com/" target="_blank">© Maxar</a>',
            tileSize: 512,
            maxZoom: 19,
            zoomOffset: -1,
            accessToken: token,
            label: 'Satellite'
    }),
    L.tileLayer('//api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            id: 'masae/ckk5lktkk6gid17js89pzd9s5',
            attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="http://www.openstreetmap.org/about/" target="_blank">© OpenStreetMap</a> <a href="https://www.maxar.com/" target="_blank">© Maxar</a>',
            tileSize: 512,
            maxZoom: 19,
            zoomOffset: -1,
            accessToken: token,
            label: 'Dark'
    }),
];
const labelLayers = {
    'fr': L.tileLayer('//api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        id: 'masae/ckk5ksscr1oil17qqfqvo4dtp',
        tileSize: 512,
        maxZoom: 19,
        zoomOffset: -1,
        accessToken: token,
        label: 'label_fr',
        pane: 'labels'
    }),
    'en': L.tileLayer('//api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        id: 'masae/ckk5kqiai0sze17p553jm2c3e',
        tileSize: 512,
        maxZoom: 19,
        zoomOffset: -1,
        accessToken: token,
        label: 'label_en',
        pane: 'labels'
    }),
};

const cartoLight = L.tileLayer('//{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="//www.openstreetmap.org/copyright">OpenStreetMap contributors</a>, &copy; <a href="//carto.com/attributions">CARTO</a>',
    subdomains: 'abcd',
    maxZoom: 19,
    label: 'Carto light'
});

const createOutdoor = function() {
    return L.tileLayer('//{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="//www.openstreetmap.org/copyright">OpenStreetMap contributors</a>, &copy; <a href="//carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
        maxZoom: 19,
        label: 'Carto light'
    });
};

export {baseLayers, labelLayers, cartoLight, createOutdoor};