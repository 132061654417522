<template>
    <div class="countries-wrapper">
        <h1> Select a country </h1>
        <p class="logout" @click="$logout()"> Logout </p>
        <div class="countries">
            <a v-for="country in countries" :key="country" :href="'/' + country" :class="[{disabled: !available(country)}, 'country']">
                <component :is="'icon-' + country" :width="'100%'" :height="'100%'" :strokeColor="'black'"/>
                <p> {{ $t(country) }} </p>
            </a>
        </div>
        <a href="https://www.masae-analytics.com" target='_blank'> <img class="masae" alt="Logo Masae" src="@/assets/img/logo_masae.png"> </a>
    </div>
</template>

<script>

export default {
    name: 'countries-select',
    beforeCreate() {
        this.countries = this.$config.countries;
    },
    methods: {
        available(code) {
            for (let countryCode of localStorage.getItem('countries').split(',')) {
                countryCode = countryCode.trim();
                if (code == countryCode) return true;
            }
            return false;
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/style/scss/components/countries'; 
</style>
