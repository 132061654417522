<template>
    <header class="as-toolbar">
        <div class="as-toolbar__group">
            <a href="https://www.yahsat.com/" class="as-logo"><img src="@/assets/img/logo_yahsat_inverted.png" alt="Yahsat logo"></a>
        </div>
        <div class="as-toolbar__group">
            <div class="as-toolbar__item" @click="$emit('play-tuto')"> <component :is="'icon-question'" :width="'30px'" :height="'30px'"/> </div>
            <div class="as-toolbar__item" @click="toCountries()"> <component :is="'icon-earth'" :width="'35px'" :height="'35px'"/> </div>
            <div class="as-toolbar__item" @click="$logout()" style="margin-top: 2px;"> <component :is="'icon-logout'" :width="'35px'" :height="'35px'"/> </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'map-header',
    methods: {
        toCountries() {
            this.$router.push('/countries');
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/scss/_variables";

.as-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 100%;
    padding: 5px 0;
    & > img { height: 100%; }
}

.as-container .as-box {
    padding: 0px 23px;
}
</style>