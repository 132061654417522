const handler = (val) => {
    return  {
        get: function(target, name) {
            return target.hasOwnProperty(name) ? target[name] : (typeof val === 'function' ? val() : val);
        }
    }
};

export default function defaultDict(defaultValue) {
    return new Proxy({}, handler(defaultValue));
}